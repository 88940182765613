import Login from '@/components/Login';
import useAccountStore from '@/store/useAccountStore';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useLayoutEffect } from 'react';

// LoginPage component
const LoginPage = (props) => {
  // destructuring props

  // context hooks
  const loggedIn = useAccountStore((state) => state.loggedIn);
  const accountType = useAccountStore((state) => state.accountType);
  const router = useRouter();
  // state

  // effect

  // queries

  // other variables/functions/handlers
  useLayoutEffect(() => {
    if (loggedIn && accountType && router) {
      router.push('/');
    }
  }, [loggedIn, accountType, router]);
  // render
  return (
    <>
      <Head>
        <title>Grapes | LOGIN</title>
      </Head>
      <Login />
    </>
  );
};

// export
export default LoginPage;
