import apple from '@/assets/Apple.svg';
import bingoScreenshot from '@/assets/bingo_screenshot.png';
import facebook from '@/assets/facebook.svg';
import google from '@/assets/google.svg';
import { GTMContext } from '@/context/GTMContext';
import useModalStore from '@/store/useModalStore';
import useWeb3AuthStore from '@/store/useWeb3AuthStore';
import {
  gtmEvent_continue_apple,
  gtmEvent_continue_apple_deposit,
  gtmEvent_continue_apple_notifications,
  gtmEvent_continue_apple_withdraw,
  gtmEvent_continue_facebook,
  gtmEvent_continue_facebook_deposit,
  gtmEvent_continue_facebook_notifications,
  gtmEvent_continue_facebook_withdraw,
  gtmEvent_continue_google,
  gtmEvent_continue_google_deposit,
  gtmEvent_continue_google_notifications,
  gtmEvent_continue_google_withdraw,
} from '@/utils/GTMFunctions';
import { WALLET_ADAPTERS } from '@web3auth/base';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useContext } from 'react';

// Login component
const Login = (props) => {
  // destructuring props

  // context hooks
  const web3auth = useWeb3AuthStore((state) => state.web3auth);
  const connectWalletWeb3 = useWeb3AuthStore((state) => state.connectWalletWeb3);
  const loginWalletError = useModalStore((state) => state.loginWalletError);
  const setLoginWalletError = useModalStore((state) => state.setLoginWalletError);
  const { setDataLayer } = useContext(GTMContext);
  // state
  const pathname = usePathname();

  // effect

  // queries

  // other variables/functions/handlers

  const handleFacebookLoginClick = () => {
    switch (pathname) {
      case '/deposit':
        setDataLayer(gtmEvent_continue_facebook_deposit());
        break;
      case '/withdraw':
        setDataLayer(gtmEvent_continue_facebook_withdraw());
        break;
      case '/notifications':
        setDataLayer(gtmEvent_continue_facebook_notifications());
        break;
      default:
        setDataLayer(gtmEvent_continue_facebook());
        break;
    }
    Web3AuthFacebookLogin();
  };

  const handleGoogleLoginClick = () => {
    switch (pathname) {
      case '/deposit':
        setDataLayer(gtmEvent_continue_google_deposit());
        break;
      case '/withdraw':
        setDataLayer(gtmEvent_continue_google_withdraw());
        break;
      case '/notifications':
        setDataLayer(gtmEvent_continue_google_notifications());
        break;
      default:
        setDataLayer(gtmEvent_continue_google());
        break;
    }
    Web3AuthGoogleLogin();
  };

  const handleAppleLoginClick = () => {
    switch (pathname) {
      case '/deposit':
        setDataLayer(gtmEvent_continue_apple_deposit());
        break;
      case '/withdraw':
        setDataLayer(gtmEvent_continue_apple_withdraw());
        break;
      case '/notifications':
        setDataLayer(gtmEvent_continue_apple_notifications());
        break;
      default:
        setDataLayer(gtmEvent_continue_apple());
        break;
    }
    Web3AuthAppleLogin();
  };

  const Web3AuthGoogleLogin = async () => {
    if (!web3auth) {
      return;
    }
    try {
      const web3authProvider = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider: 'google',
      });
      connectWalletWeb3(web3authProvider);
    } catch (error) {
      setLoginWalletError(true);
      console.log('Error :', error);
    }
  };

  const Web3AuthFacebookLogin = async () => {
    if (!web3auth) {
      return;
    }
    try {
      const web3authProvider = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider: 'facebook',
      });
      connectWalletWeb3(web3authProvider);
    } catch (error) {
      setLoginWalletError(true);
      console.log('Error :', error);
    }
  };

  const Web3AuthAppleLogin = async () => {
    if (!web3auth) {
      return;
    }
    try {
      const web3authProvider = await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider: 'apple',
      });
      connectWalletWeb3(web3authProvider);
    } catch (error) {
      setLoginWalletError(true);
      console.log('Error :', error);
    }
  };

  // render
  return (
    <div className="login">
      <div className="login__image">
        <Image src={bingoScreenshot} alt="home" />
      </div>
      <div className="login__card">
        <div className="login__card__title">Login</div>
        <div className="login__card__subtitle">
          Log in to manage your funds. Please use the same account as your game login
        </div>
        <div className="login__card__social">
          <div className="login__card__social-button">
            <button onClick={handleFacebookLoginClick}>
              <Image src={facebook} /> Continue with Facebook
            </button>
          </div>
          <div className="login__card__social-button">
            <button onClick={handleGoogleLoginClick}>
              <Image src={google} />
              Continue with Google
            </button>
          </div>
          <div className="login__card__social-button">
            <button onClick={handleAppleLoginClick}>
              <Image src={apple} />
              Continue with Apple
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// export
export default Login;
